import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import React, { Suspense } from "react";
import { NotificationContainer } from "components/react-notifications";
import PrivateRoute from "helpers/authHelper";
import Preloader from "components/loader/preloader";

const ManageCompany = React.lazy(() => import("views/manage"));
const NavigationApp = React.lazy(() => import("views/app"));
const UserPage = React.lazy(() => import("views/user"));

const AppMain = () => {
    return (
        <div className="w-full font-public">
            <NotificationContainer />
            <Suspense fallback={<Preloader />}>
                <Router>
                    <Switch>
                        <Redirect exact from="/" to="/manage" />
                        <Route
                            path="/user"
                            render={(match) => <UserPage {...match} />}
                        />

                        <PrivateRoute
                            path="/manage"
                            component={ManageCompany}
                        />
                        <PrivateRoute path="/app" component={NavigationApp} />
                    </Switch>
                </Router>
            </Suspense>
        </div>
    );
};

export default AppMain;
