import React from "react";

const Preloader = () => {
    return (
        <div
            id="preloader"
            className="flex h-screen items-center justify-center"
        >
            <div className="preloader-content relative">
                <div className="circular-progress" />
                <img
                    src={require("assets/logo/notain-mobile.png")}
                    id="logo"
                    alt="Logo"
                    className="logo"
                />
            </div>
        </div>
    );
};

export default Preloader;
