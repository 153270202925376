import qs from "qs";

import { API } from "./host";

export const requestData = (action, data, method = "POST") => {
    return fetch(API + action, {
        method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: qs.stringify(data),
    })
        .catch(() => {})
        .then((response) => response.json());
};
