import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import { authClear, authInit } from "../redux/actions";
import { requestData } from "../config/request";

const PrivateRoute = ({ component: Component, init, clear, ...rest }) => {
    const request = async (action, data, method) => {
        const AuthKey = localStorage.getItem("GuguPOS-Auth-Key");
        const formData = { token: AuthKey, ...data };

        return requestData(action, formData, method).then((response) => {
            if (response.auth !== undefined && !response.auth) clear();

            return response;
        });
    };

    const setComponent = (props) => {
        const Auth = localStorage.getItem("GuguPOS-Auth");
        const AuthKey = localStorage.getItem("GuguPOS-Auth-Key");
        const AuthUser = localStorage.getItem("GuguPOS-Auth-User");

        var urlParams = new URLSearchParams(window.location.search);
        var paramsGuguPOSAuthUser = urlParams.get("GuguPOS-Auth-User");
        var CompanyCode = urlParams.get("Company-Code");

        if (paramsGuguPOSAuthUser) {
            var GuguPOSAuthUser = JSON.parse(paramsGuguPOSAuthUser);

            localStorage.setItem("GuguPOS-Auth", true);
            localStorage.setItem("GuguPOS-Auth-Key", GuguPOSAuthUser.token);
            localStorage.setItem(
                "GuguPOS-Auth-User",
                JSON.stringify(GuguPOSAuthUser),
            );

            init(true, GuguPOSAuthUser.token, GuguPOSAuthUser);

            window.location.href = "/app/" + CompanyCode + "/Home";
            return;
        }

        if (Auth != null && AuthKey != null && AuthUser != null) {
            init(Auth, AuthKey, JSON.parse(AuthUser));
            return <Component {...props} request={request} />;
        }

        return (
            <Redirect
                to={{
                    pathname: "/user/login",
                    state: { from: props.location },
                }}
            />
        );
    };

    return <Route {...rest} render={setComponent} />;
};

export default connect(null, {
    init: authInit,
    clear: authClear,
})(PrivateRoute);
