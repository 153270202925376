import React from "react";
import "./index.css";
import Main from "./layout/app-main";
import "moment/locale/id";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
    createRoot(rootElement).render(
        <Provider store={store}>
            <Component />
        </Provider>,
    );
};

renderApp(Main);

// reportWebVitals();
