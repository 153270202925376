import { MENU_ID } from "../actions";

const INIT_STATE = {
    paramsCode: null,
    urlId: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MENU_ID:
            return { ...state, urlId: action.payload };

        default:
            return { ...state };
    }
};
