import {
    ACCESS_DATA,
    AUTH_CLEAR,
    AUTH_COMPANY,
    AUTH_INIT,
    AUTH_SET,
} from "../actions";

const INIT_STATE = {
    token: "",
    auth: false,
    user: null,
    company: null,
    accessData: null,
    akunLevel: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTH_INIT:
            return {
                ...state,
                auth: action.payload.auth,
                token: action.payload.token,
                user: action.payload.user,
            };

        case AUTH_SET:
            localStorage.setItem("GuguPOS-Auth", true);
            localStorage.setItem("GuguPOS-Auth-Key", action.payload.token);
            localStorage.setItem(
                "GuguPOS-Auth-User",
                JSON.stringify(action.payload.user),
            );

            return {
                ...state,
                auth: true,
                token: action.payload.token,
                user: action.payload.user,
            };

        case AUTH_CLEAR:
            localStorage.clear();

            return {
                ...state,
                auth: false,
                token: "",
            };

        case AUTH_COMPANY:
            return {
                ...state,
                company: action.payload.company,
            };
        case ACCESS_DATA:
            return {
                ...state,
                accessData: action.payload.accessData,
                akunLevel: action.payload.akunLevel,
            };

        default:
            return { ...state };
    }
};
